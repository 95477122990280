import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Container } from 'semantic-ui-react';
import faker from 'faker';

import Image from 'src/components/Image';
import SEO from 'src/components/SEO';
import VideoPlayer from 'src/components/shared/VideoPlayer';
import Selectors from 'src/state/root-selectors';

const videos = [
    {
        id: faker.random.uuid(),
        src: 'https://stream.mux.com/Ug8Ebbw4IVABflWIQQxlfYm8wuiWfpWx.m3u8',
        name: 'Movie with Closed Captions',
    },
    {
        id: faker.random.uuid(),
        src:
            'https://stream.mux.com/9eDR01apdOrQQL00hMmMbZBuFcSCASAqwiooZYdbQAi7s.m3u8',
        name: 'P.volve workout',
    },
];

const IndexPage = ({ user }) => {
    const [videoSrc, setVideoSrc] = useState();

    const openVideoPlayer = (source) => {
        setVideoSrc(source);
    };

    const closeVideoPlayer = () => {
        setVideoSrc(null);
    };

    const playerOptions = {
        /**
         * Optional: Any additional VideoJs options
         * Will override defaults in VideoPlayer.js
         */
    };

    /**
     * Optional: any additional event handling
     * Event analytics are handled inside VideoPlayer.js
     *
     * In this example, we are using `onClose`
     * to update page-level video state.
     */
    const playerEvents = {
        onClose: closeVideoPlayer,
        onPlay: () => console.log('onPlay'),
        onPause: () => console.log('onPause'),
    };

    return (
        <>
            <SEO title="P.Volve" />
            <Container>
                <div style={{ margin: `5rem` }}>
                    <h1>Hi {user?.given_name || 'people'}!</h1>
                </div>
                <div style={{ maxWidth: `300px`, margin: `5rem` }}>
                    <h2>Here's an image using gatsby-sharper-plugin:</h2>
                    <Image />
                </div>
                <div style={{ margin: `5rem` }}>
                    <h2>
                        Here's a controlled mux video using Video.js player:
                    </h2>
                    {videos.map((video, index) => (
                        <Button
                            content={`Play ${video.name}`}
                            onClick={() => openVideoPlayer(video.src)}
                            key={index}
                        />
                    ))}
                </div>
            </Container>
            <VideoPlayer
                src={videoSrc}
                options={playerOptions}
                events={playerEvents}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    user: Selectors.auth.user(state),
});

export default connect(mapStateToProps)(IndexPage);
